import { BrowserTracing } from '@sentry/browser';
import type { Integration } from '@sentry/types';
import { addHistoryInstrumentationHandler } from '@sentry/utils';

export function framelessIntegration(): Integration {
    return {
        name: 'Frameless',
        setupOnce() {},
        processEvent(event) {
            if (event.type === 'transaction') {
                // Add Spacesuit version as tag
                const spacesuitVersion = window.SPACESUIT_VERSION;

                if (spacesuitVersion) {
                    event.tags = {
                        ...event.tags,
                        spacesuitVersion,
                    };
                }

                // Set transaction name
                if (event.transaction) {
                    event.transaction = getTransactionName(event.transaction);
                }
            }

            return event;
        },
    };
}

export function framelessBrowserTracingIntegration(): Integration {
    return new BrowserTracing({
        routingInstrumentation(customStartTransaction) {
            // Start a pageload transaction on hard navigation or refresh
            let activeTransaction = customStartTransaction({
                name: window.location.href,
                op: 'pageload',
            });

            addHistoryInstrumentationHandler(
                ({ to, from }: { to: string; from?: string }) => {
                    // For some reason frameless.js:loadActualPage performs a
                    // replaceState even if the url is the same.
                    if (from === undefined) {
                        return;
                    }

                    if (from !== to) {
                        if (activeTransaction) {
                            activeTransaction.end();
                        }

                        activeTransaction = customStartTransaction({
                            name: to,
                            op: 'navigation',
                        });
                    }
                }
            );

            // Explicitly finish active transaction when page notifies it is done loading
            window.addEventListener('pageLoad-lazyLoadDone', () => {
                if (activeTransaction) {
                    activeTransaction.end();
                }
            });
        },
    });
}

export function getTransactionName(
    href: string,
    origin = window.location.origin
): string {
    return new URL(href, origin).pathname;
}

import type { Event as SentryEvent, Integration } from '@sentry/types';

/**
 * There's nothing to be done about this error and it's not actionable.
 *
 * @see https://tripletex.sentry.io/share/issue/848666ad6f1e4fe5863c482652fc7270/
 */
export function resizeObserverIntegration(): Integration {
    return {
        name: 'ResizeObserver',
        setupOnce() {},
        processEvent(event) {
            if (isResizeObserverExceptionEvent(event)) {
                return null;
            }

            return event;
        },
    };
}

function isResizeObserverExceptionEvent(event: SentryEvent): boolean {
    if (!event.message) {
        return false;
    }

    return event.message.includes('ResizeObserver loop');
}

import { breadcrumbsIntegration, BrowserOptions, init } from '@sentry/browser';
import { apiErrorIntegration } from './sentry/api-error';
import { eventSamplerIntegration } from './sentry/event-sampler';
import {
    framelessBrowserTracingIntegration,
    framelessIntegration,
} from './sentry/frameless';
import { jsonRpcIntegration } from './sentry/json-rpc';
import { legacyExceptionLoggingIntegration } from './sentry/legacy-exception-logging';
import { missingDynamicModuleIntegration } from './sentry/missing-dynamic-module-filter';
import { projectMenuMappingIntegration } from './sentry/project-menu-mapping';
import { resizeObserverIntegration } from './sentry/resize-observer';
import { tourMeIntegration } from './sentry/tour-me';
import { tracePropagationTargets } from './sentry/tracing';

const dsn =
    'https://90935ce7d70e46d6af168cd9593a3c4d@o558494.ingest.sentry.io/5692008';
const isProduction = document.location.host === 'tripletex.no';

const options: BrowserOptions = {
    dsn,
    enabled: isProduction,
    environment: isProduction ? 'production' : 'development',
    release: window.sentryConfig.release,
    initialScope: {
        tags: {
            ...window.sentryConfig.tags,
            contextId: window.contextId,
        },
        user: {
            id: window.loginEmployeeId.toString(),
        },
    },
    integrations: [
        framelessBrowserTracingIntegration(),
        breadcrumbsIntegration({
            dom: {
                serializeAttribute: ['data-testid', 'data-trackingid'],
            },
        }),
        framelessIntegration(),
        jsonRpcIntegration(),
        resizeObserverIntegration(),
        missingDynamicModuleIntegration(),
        apiErrorIntegration(),
        legacyExceptionLoggingIntegration(),
        tourMeIntegration(),
        projectMenuMappingIntegration(),
        eventSamplerIntegration(isProduction ? 0.1 : 1.0),
    ],
    denyUrls: [/^chrome-extension:/],
    tracesSampleRate: isProduction ? 0.0025 : 1.0,
    tracePropagationTargets,
};

init(options);

import type { Event as SentryEvent, Integration } from '@sentry/types';

/**
 * When users navigate between pages while a deployment is in progress,
 * the browser will sometimes try to fetch a module that no longer exists.
 *
 * @see https://tripletex.sentry.io/share/issue/be000de89d564a838d8bb52e9830062a/
 */
export function missingDynamicModuleIntegration(): Integration {
    return {
        name: 'MissingDynamicModule',
        setupOnce() {},
        processEvent(event) {
            if (isMissingDynamicModuleEvent(event)) {
                return null;
            }

            return event;
        },
    };
}

function isMissingDynamicModuleEvent(event: SentryEvent): boolean {
    if (event.message) {
        return event.message.includes(
            'Failed to fetch dynamically imported module'
        );
    }

    return false;
}

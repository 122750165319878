import { Integration } from '@sentry/types';

export function projectMenuMappingIntegration(): Integration {
    return {
        name: 'ProjectMenuMapping',
        setupOnce() {},
        processEvent(event) {
            if (event.request && event.transaction === '/execute/projectMenu') {
                const request = event.request;

                if (request.url === undefined) {
                    return event;
                }

                const url = new URL(request.url);

                const projectId = url.searchParams.get('projectId');
                const templateId = url.searchParams.get('templateId');
                const isProject = url.searchParams.get('isProject');
                const isOffer = url.searchParams.get('isOffer');

                if (projectId === '0') {
                    if (isProject === 'false' && isOffer === 'true') {
                        event.transaction += '/create-offer';
                    } else if (isOffer === 'true') {
                        event.transaction += '/create-project-offer';
                    } else if (isProject === 'false') {
                        event.transaction += '/create-order';
                    } else {
                        if (templateId !== null) {
                            event.transaction += '/create-subproject-wizard';
                        } else {
                            event.transaction += '/create-project-wizard';
                        }
                    }
                } else if (projectId !== null) {
                    event.transaction += '/details';
                }
            }

            return event;
        },
    };
}

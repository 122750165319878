import { setTag } from '@sentry/browser';
import type { Integration } from '@sentry/types';

export function tourMeIntegration(): Integration {
    return {
        name: 'TourMe',
        setupOnce() {
            const handleStart: EventListener = (event) => {
                const tourId =
                    event instanceof CustomEvent &&
                    event.detail.tourId !== undefined
                        ? event.detail.tourId
                        : null;

                setTag('tourme.name', tourId);
            };

            const handleStop: EventListener = () => {
                setTag('tourme.name', undefined);
            };

            window.addEventListener('tour-me-cancel', handleStop);
            window.addEventListener('tour-me-finish', handleStop);
            window.addEventListener('tour-me-run', handleStart);
        },
    };
}

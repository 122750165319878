import { getCurrentScope } from '@sentry/browser';
import type { Integration } from '@sentry/types';

/**
 * A temporary replacement for clientLogging until we have
 * a replacement for Sync.remoteLogException
 */
export function legacyExceptionLoggingIntegration(): Integration {
    return {
        name: 'LegacyExceptionLogging',
        setupOnce() {
            window.addEventListener('error', (event: ErrorEvent) => {
                const scope = getCurrentScope();
                // @ts-expect-error No other way of accessing protected property _tags
                const tags = scope._tags;
                const message = getMessage(event);
                const stack = getStack(event);

                if (window.jsonrpc === undefined) {
                    return;
                }

                window.jsonrpc.Sync.remoteLogException(message, stack, {
                    team: tags.team,
                    url: location.href,
                });
            });
        },
    };
}

function getMessage(event: ErrorEvent): string {
    return event.error instanceof Error ? event.error.message : event.message;
}

function getStack(event: ErrorEvent): string {
    if (event.error instanceof Error && event.error.stack !== undefined) {
        return event.error.stack;
    }

    return `${event.filename}:${event.lineno}:${event.colno}`;
}

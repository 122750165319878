import type { Integration } from '@sentry/types';

/**
 * We might want to relay events to other services before sampling them,
 * which is why we cannot use options.sampleRate as it samples events
 * before they reach integrations.
 *
 * @see https://github.com/getsentry/sentry-javascript/blob/17e4cb8f460246ca22ef452f3957d222f4c94849/packages/core/src/baseclient.ts#L621
 */
export function eventSamplerIntegration(sampleRate = 1): Integration {
    return {
        name: 'EventSampler',
        setupOnce() {},
        processEvent(event) {
            if (event.type !== 'transaction' && Math.random() > sampleRate) {
                return null;
            }

            return event;
        },
    };
}

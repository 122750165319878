import type { Event as SentryEvent, Integration } from '@sentry/types';

type JSONRPCEvent = SentryEvent & {
    tags: {
        'json-rpc.class'?: string;
        'json-rpc.method'?: string;
        'json-rpc.name'?: string;
    };
};

type JSONRPCError = {
    javaClass?: string;
    msg?: string;

    /**
     * @see tripletex/common-lib/src/main/java/no/tripletex/common/exception/JsonRpcException.java
     */
    rpcCodeName?: string;
};

const TAG_CLASS = 'json-rpc.class';
const TAG_METHOD = 'json-rpc.method';
const TAG_ERROR_NAME = 'json-rpc.name';

/**
 * Our JSON-RPC client captures a lot of exceptions that aren't really
 * actionable such as validation errors and user session timeouts.
 */
export function jsonRpcIntegration(): Integration {
    return {
        name: 'JSON-RPC',
        setupOnce() {},
        processEvent(event) {
            // Attempt to add serialized details to the event
            if (!isJSONRPCEvent(event)) {
                addSerializedDetails(event);
            }

            if (isJSONRPCEvent(event)) {
                if (isValidationErrorEvent(event)) {
                    return null;
                }

                if (isNotLoggedInExceptionEvent(event)) {
                    return null;
                }

                if (isAdviceExceptionEvent(event)) {
                    return null;
                }

                if (isObjectDeletedExceptionEvent(event)) {
                    return null;
                }

                if (isCustomerFormMvaRegExceptionEvent(event)) {
                    return null;
                }
            }

            return event;
        },
    };
}

function isValidationErrorEvent(event: JSONRPCEvent): boolean {
    return (
        event.tags[TAG_CLASS] ===
        'no.tripletex.common.exception.ValidationException'
    );
}

function isNotLoggedInExceptionEvent(event: JSONRPCEvent): boolean {
    return (
        event.tags[TAG_CLASS] ===
        'no.tripletex.common.exception.NotLoggedInException'
    );
}

function isAdviceExceptionEvent(event: JSONRPCEvent): boolean {
    return (
        event.tags[TAG_CLASS] ===
        'no.tripletex.common.exception.AdviceException'
    );
}

function isObjectDeletedExceptionEvent(event: JSONRPCEvent): boolean {
    return (
        event.tags[TAG_CLASS] ===
        'no.tripletex.common.exception.ObjectDeletedException'
    );
}

/**
 * @see https://sentry.io/share/issue/91bec686081c42c385eb91c4ecb1a02f/
 */
function isCustomerFormMvaRegExceptionEvent(event: JSONRPCEvent): boolean {
    return event.tags[TAG_METHOD] === 'CustomerForm.isInMvaReg';
}

// TODO: Clearly we need something more stable than these three values
function isJSONRPCEvent(event: SentryEvent): event is JSONRPCEvent {
    return (
        event.tags?.[TAG_CLASS] !== undefined ||
        event.tags?.[TAG_METHOD] !== undefined ||
        event.tags?.[TAG_ERROR_NAME] !== undefined
    );
}

/**
 * We add tags in the JSON-RPC client when an exception is thrown, but
 * sometimes details are wrapped in an extra object.
 */
function addSerializedDetails(event: SentryEvent): void {
    const details = (event.extra?.__serialized__ ?? event.extra?.error) as
        | JSONRPCError
        | undefined;

    if (details === undefined) {
        return;
    }

    if (event.tags === undefined) {
        event.tags = {};
    }

    // Class
    if (
        event.tags[TAG_CLASS] === undefined &&
        details.javaClass !== undefined
    ) {
        event.tags[TAG_CLASS] = details.javaClass;
    }

    // Method
    if (
        event.tags[TAG_METHOD] === undefined &&
        details.rpcCodeName !== undefined
    ) {
        event.tags[TAG_METHOD] = details.rpcCodeName;
    }

    // RPC error code name
    if (
        event.tags[TAG_ERROR_NAME] === undefined &&
        details.rpcCodeName !== undefined
    ) {
        event.tags[TAG_ERROR_NAME] = details.rpcCodeName;
    }
}

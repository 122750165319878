import type { Integration } from '@sentry/types';
import { APIError } from '@tlx/astro-shared';

export function apiErrorIntegration(): Integration {
    return {
        name: 'APIError',
        setupOnce() {},
        processEvent(event, hint) {
            const error = hint?.originalException;

            if (isOfflineError(error)) {
                return {
                    ...event,
                    tags: {
                        ...event.tags,
                        offline: true,
                    },
                };
            }

            if (isAPIError(error)) {
                // Ignore expired session errors
                if (isExpiredSessionError(error)) {
                    return null;
                }

                event.extra = {
                    ...event.extra,
                    ...error.details,
                };
            }

            return event;
        },
    };
}

/**
 * @see https://tripletex.no/v2-docs/
 */
function isExpiredSessionError(error: APIError): boolean {
    return error.details.status === 401 && error.details.code === 3000;
}

function isAPIError(error: unknown): error is APIError {
    return error instanceof Error && error.name === 'APIError';
}

function isOfflineError(error: unknown): boolean {
    return (
        error instanceof Error &&
        error.name === 'TypeError' &&
        error.message === 'Failed to fetch' &&
        navigator.onLine === false
    );
}
